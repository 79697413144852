import React from "react"
import { graphql } from "gatsby"
import { css } from "@emotion/core"
import PropTypes from "prop-types"
import Img from "gatsby-image"
import { FormattedMessage } from "react-intl"

import Layout from "../components/layout"
import SEO from "../components/seo"

const About = ({
  data: {
    contentfulAsset: { fluid },
    contentfulText: {
      content: {
        // text,
        childMarkdownRemark: { html },
      },
    },
    inquiries: {
      content: {
        // text,
        childMarkdownRemark: { html: inquiries },
      },
    },
  },
}) => (
  <Layout title="">
    <SEO title="Home" />
    <div
      css={css`
        width: 95%;
        display: flex;
        border-bottom: 1px solid #bbb;
        flex-direction: column;
        margin: 0px auto;
      `}
    >
      <div
        css={css`
          width: 100%;
          display: flex;
          padding: 4% 0%;
          border-bottom: 1px solid #bbb;
        `}
      >
        <div
          css={css`
            width: 40%;
            height: 100%;
          `}
        >
          <Img fluid={fluid} />
        </div>
        <div
          css={css`
            padding: 1% 2%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            color: #737373;

            p {
              padding: 0;
              margin: 0px 0px;
              margin-bottom: 3px;
            }

            :lang(ar) {
              font-size: 14px;
            }
          `}
        >
          <div>
            <FormattedMessage id="about.short_bio" />
          </div>
          <div dangerouslySetInnerHTML={{ __html: inquiries }} />
        </div>
      </div>
      <div
        css={css`
          padding: 3% 0%;
          line-height: 2em;
          color: #737373;
          font-size: 15px;
        `}
        dangerouslySetInnerHTML={{ __html: html }}
      />
    </div>
  </Layout>
)

About.propTypes = {
  data: PropTypes.shape({
    contentfulAsset: PropTypes.shape({
      fluid: PropTypes.object.isRequired,
    }).isRequired,
    contentfulText: PropTypes.shape({
      content: PropTypes.shape({
        childMarkdownRemark: PropTypes.shape({
          html: PropTypes.string.isRequired,
        }).isRequired,
      }).isRequired,
    }).isRequired,
    inquiries: PropTypes.shape({
      content: PropTypes.shape({
        childMarkdownRemark: PropTypes.shape({
          html: PropTypes.string.isRequired,
        }).isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
}

export const query = graphql`
  query about($lang: String = "en-US") {
    contentfulAsset(contentful_id: { eq: "181Bm1647K03tao9L85OqA" }) {
      fluid {
        ...GatsbyContentfulFluid
      }
    }
    contentfulText(textId: { eq: "jordan" }, node_locale: { eq: $lang }) {
      content {
        childMarkdownRemark {
          html
        }
      }
    }
    inquiries: contentfulText(
      textId: { eq: "inquiries" }
      node_locale: { eq: $lang }
    ) {
      content {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`

export default About
